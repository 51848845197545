import { createReducer, on } from '@ngrx/store';
import { Alert } from '../../models';
import * as pageActions from '../actions/alerts.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface AlertState {
  entities: { [id: number]: Alert };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: AlertState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const AlertReducer = createReducer(
  initialState,

  on(pageActions.LoadAlerts, (state: AlertState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadAlertsSuccess, (state: AlertState, { Alert }) => {
    const entities = ToEntities(Alert, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadAlertsFail, (state: AlertState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getAlertEntities = (state: AlertState) => state.entities;
export const getAlertLoading = (state: AlertState) => state.loading;
export const getAlertLoaded = (state: AlertState) => state.loaded;
export const getAlertError = (state: AlertState) => state.error;
