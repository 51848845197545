import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects } from './store';
import { SocialChannelsService, SocialConfigService } from './services';
import { SocialConfig } from './models';

export function ProvideSocialsNgrx(config: Partial<SocialConfig>): EnvironmentProviders {
  // using default values for missing properties
  const merged: SocialConfig = {
    apiUrl: 'api/social-channels',
    ...config,
  };

  return makeEnvironmentProviders([
    provideState('social-media', reducers),
    provideEffects(effects),
    SocialChannelsService,
    {
      provide: SocialConfigService,
      useValue: merged,
    },
  ]);
}
