import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromAlerts from '../reducers/alerts.reducer';

export const getAlertsState = createSelector(fromFeature.getAlertState, (state: fromFeature.AlertState) => state.Alert);

export const getAlertEntities = createSelector(getAlertsState, fromAlerts.getAlertEntities);

export const getAllAlerts = createSelector(getAlertEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getActiveAlert = createSelector(getAllAlerts, alerts => {
  if (alerts.length === 1) {
    return alerts[0];
  }

  return alerts.filter(alert => new Date(alert.publishFrom) <= new Date()).sort((a, b) => (a.publishFrom < b.publishFrom ? -1 : 1))[0];
});

export const getAlertLoading = createSelector(getAlertsState, fromAlerts.getAlertLoading);
export const getAlertLoaded = createSelector(getAlertsState, fromAlerts.getAlertLoaded);
export const getAlertError = createSelector(getAlertsState, fromAlerts.getAlertError);
