import * as fromAlert from './alerts.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'alerts';

export interface AlertState {
  Alert: fromAlert.AlertState;
}

export const reducers: ActionReducerMap<AlertState> = {
  Alert: fromAlert.AlertReducer,
};

export const getAlertState = createFeatureSelector<AlertState>(featureKey);

export const metaReducers: MetaReducer<AlertState>[] = !environment.production ? [] : [];
