<nav>
  <ul class="follow-button-list d-flex gap-2">
    @for (item of channels(); track item) {
      <li class="follow-button-list__item">
        <a [href]="item.url" target="_blank" [attr.aria-label]="item.title" class="button button--light-secondary button--md button-icon">
          <span class="sr-only">{{ item.title }}</span>

          <fstr-icon [anchor]="item.icon || 'icon-link'" [title]="item.title" class="text-secondary"></fstr-icon>
        </a>
      </li>
    }
  </ul>
</nav>
