import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SocialConfigService } from './social-config.service';
import { SocialChannel, SocialConfig } from '../models';
@Injectable()
export class SocialChannelsService {
  public location: { [identifier: string]: Location } = {};

  private get _apiUrl() {
    if (!this.config.apiUrl) return `${this.BASE_URL || ''}api/social-channels`;
    if (this.isAbsolute(this.config.apiUrl)) {
      return this.config.apiUrl;
    }

    return `${this.BASE_URL || ''}${this.config.apiUrl}`;
  }

  constructor(
    private Http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string,
    @Inject(SocialConfigService) private config: SocialConfig
  ) {}

  getSocialChannels() {
    return this.Http.get<SocialChannel[]>(this._apiUrl);
  }

  init() {}

  isAbsolute(url: string) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
}
