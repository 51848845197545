import { Component, ChangeDetectionStrategy, Input, input } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialChannel } from '../../models';
import { IconComponent } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-social-channel-buttons',
  templateUrl: './social-channel-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styleUrls: ['./social-channel-buttons.component.scss'],
  imports: [IconComponent],
})
export class SocialChannelButtonsComponent {
  channels = input.required<SocialChannel[]>();
}
