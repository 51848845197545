<footer class="footer bg-primary-100">
  <div class="container">
    <section class="row section-inset-t text-start justify-content-between gap-4">
      <div class="col-md-7 bg-primary p-4 p-lg-5 footer__cta">
        <h1 class="h2 text-light text-wrap">{{ 'footer-cta-contact-tekst' | fromDictionary }}</h1>
        <a [href]="'footer-cta-contact-link' | fromDictionary" class="button button--light-primary mt-3">
          <span>{{ 'footer-cta-contact-link-label' | fromDictionary }}</span>
        </a>
      </div>

      @if (footerMenuLeft(); as menu) {
        <div class="col-md-6">
          @if (menu.title) {
            <h2 class="h3 mb-2">{{ menu.title }}</h2>
          }

          <ul class="lh-lg">
            @for (subitem of menu.menu | ofMenuType: 'MenuItem'; track subitem) {
              <li class="d-flex align-items-center gap-2 footer__nav-list__item">
                <fstr-link
                  [cssClasses]="'button button--dark button-link fw-normal'"
                  [button]="{ text: subitem.title, url: subitem.url || '', routerLink: subitem.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: subitem.url === '/' }"
                  [showText]="false"
                >
                  {{ subitem.title }}
                  @if (!subitem.routerLink) {
                    <span class="mt-1">
                      <fstr-icon anchor="line-icon-external-link"></fstr-icon>
                    </span>
                  }
                </fstr-link>
              </li>
            }
          </ul>
        </div>
      }
      @if (footerMenuRight(); as menu) {
        <div class="mb-4 col-md-9">
          @if (menu.title) {
            <h2 class="h3 mb-2">{{ menu.title }}</h2>
          }

          <article class="d-flex bg-light align-items-start my-3 rounded-5 gap-2 p-3">
            <div class="chip chip--secondary-100 position-relative mt-2">
              <div class="pulsing-online-dot chip chip--success chip--pulse"></div>
              <fstr-icon [anchor]="'line-icon-phone'"></fstr-icon>
            </div>
            <div class="d-flex flex-column gap-2 flex-xl-row">
              <div class="w-xl-50">
                <a href="" class="text-decoration-none text-dark">
                  <em class="d-inline-block lh-1 fs-body-xs">{{ 'noodnummer-alarmcentrale-label-tekst' | fromDictionary }}</em>
                  <span class="fw-bold d-block">{{ 'noodnummer-alarmcentrale' | fromDictionary }}</span>
                </a>
              </div>
              <div class="w-xl-50">
                <a href="" class="text-decoration-none text-dark">
                  <em class="d-inline-block lh-1 fs-body-xs">{{ 'personenalarmering-nummer-label-tekst' | fromDictionary }}</em>
                  <span class="fw-bold d-block">{{ 'personenalarmering-nummer' | fromDictionary }}</span>
                </a>
              </div>
            </div>
          </article>

          <ul class="lh-lg">
            @for (subitem of menu.menu | ofMenuType: 'MenuItem'; track subitem) {
              <li class="">
                <fstr-link
                  [cssClasses]="'button button--dark button-link fw-normal'"
                  [button]="{ text: subitem.title, url: subitem.url || '', routerLink: subitem.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: subitem.url === '/' }"
                  [showText]="false"
                >
                  {{ subitem.title }}
                </fstr-link>
              </li>
            }
          </ul>
        </div>
      }
    </section>

    <section class="d-flex align-items-center mt-5 gap-3 flex-column flex-md-row">
      <a [routerLink]="'/'">
        <img src="/assets/gfx/logo-eurocross-horizontal.svg" [alt]="'meta-titel' | fromDictionary" />
      </a>

      <div>
        <app-social-channel-buttons [title]="'footer-socials-titel' | fromDictionary" [channels]="socials()"></app-social-channel-buttons>
      </div>

      <a [href]="'footer-nieuwsbrief-aanmelden-link' | fromDictionary" class="button button-link-chip button--secondary">
        <span>{{ 'footer-nieuwsbrief-aanmelden-link-label' | fromDictionary }}</span>
        <fstr-icon anchor="icon-arrow-right"></fstr-icon>
      </a>
    </section>

    <ul class="footer__nav-list py-5 d-flex gap-4 flex-wrap">
      <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
      <li class="footer__nav-list__item">
        <button (click)="openCookie()" class="button button--dark h-auto px-0 button-link fw-normal">
          {{ 'cookie-settings' | fromDictionary }}
        </button>
      </li>

      @if (footerMenuBottom(); as menuBottom) {
        @for (menuItem of menuBottom.menu | ofMenuType: 'MenuItem'; track menuItem.guid) {
          <li class="footer__nav-list__item">
            <fstr-link
              [cssClasses]="'button button--dark h-auto px-0 button-link fw-normal'"
              [button]="{ text: menuItem.title, url: menuItem.url || '', routerLink: menuItem.routerLink }"
              [routerLinkActive]="'is--active'"
              [routerLinkActiveOptions]="{ exact: menuItem.url === '/' }"
              [showText]="false"
            >
              {{ menuItem.title }}
            </fstr-link>
          </li>
        }
      }
    </ul>
    <app-json-ld [json]="schema()"></app-json-ld>
  </div>
</footer>
