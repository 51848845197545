import { Component, PLATFORM_ID, Renderer2, inject, computed, effect, signal } from '@angular/core';

import { isPlatformBrowser, DOCUMENT, JsonPipe, ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel, NavigationError, Scroll } from '@angular/router';
import { Store } from '@ngrx/store';
import { FromDictionaryPipe, getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { fade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfigService, CookieNoticeComponent, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { FromMenuPipe, GetMenuPipe, getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { getTheme } from './store/selectors/theme.selector';
import { toSignal } from '@angular/core/rxjs-interop';
import { TopbarComponent } from './standalone/components/topbar/topbar.component';
import { FooterComponent } from './standalone/components/footer/footer.component';
import { LoaderIndicatorComponent } from './standalone/components/loading-indicator/loading-indicator.component';
import { GridOverlayComponent } from './standalone/components/grid-overlay/grid-overlay.component';
import { getAllSocialChannels } from './social-media/store';
import { AlertBannerComponent } from './alert-banner/components/alert-banner/alert-banner.component';
import { Alert } from './alert-banner/models';
import { getActiveAlert } from './alert-banner/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterOutlet,
    TopbarComponent,
    FooterComponent,
    LoaderIndicatorComponent,
    CookieNoticeComponent,
    FromDictionaryPipe,
    GridOverlayComponent,
    FromMenuPipe,
    GetMenuPipe,
    AlertBannerComponent,
    JsonPipe,
  ],
})
export class AppComponent {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);
  private cookieConfig = inject(CookieConfigService);
  private viewportScroller = inject(ViewportScroller);

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  cookiePrefs$ = this.store.selectSignal(getCookiePreferences);
  theme$ = this.store.selectSignal(getTheme);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);
  socials$ = this.store.selectSignal(getAllSocialChannels);
  alert$ = this.store.selectSignal(getActiveAlert);
  scrollToOffset = 100;
  // private routerEvents$ = toSignal(
  //   this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd, NavigationCancel, NavigationError].some(b => e instanceof b)))
  // );
  private routerEvents$ = toSignal(
    this.router.events.pipe(
      filter(e => [NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Scroll].some(b => e instanceof b))
    )
  );
  loading$ = computed(
    () =>
      !(
        this.routerEvents$() instanceof NavigationEnd ||
        this.routerEvents$() instanceof NavigationCancel ||
        this.routerEvents$() instanceof NavigationError ||
        this.routerEvents$() instanceof Scroll
      )
    // true
  );
  // loaded$ = computed(() => true);
  loaded$ = computed(() => {
    return this.dictionaryLoaded$() && this.menuLoaded$();
  });

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  alert = signal<Alert>({
    id: 1,
    cssClass: 'danger',
    text: 'Bent u getroffen door het noodweer in Europa? ',
    publishFrom: new Date(),
    publishUntil: new Date(),
    primaryButton: {
      text: 'Bekijk liveblog',
      url: '/',
    },
    secondaryButton: {
      text: 'Bekijk FAQ',
      url: '/',
    },
  });

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (event instanceof NavigationEnd && this.cookiePrefs$()?.analytical) {
      if ((<any>window).gtag) {
        (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      // this.document.querySelector('body').classList.add('set--in');
      this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    if (event instanceof Scroll) {
      const urlFragment = event.anchor;
      // if we can smooth scroll to the fragment, do it. Otherwise fallback on build in anchor scrolling
      if (event.routerEvent.id > 1 && urlFragment && document.getElementById(urlFragment)) {
        this.smoothScroll(document.getElementById(urlFragment));
        return;
      }
      if (event.anchor) {
        // If anchor exists, scroll to anchor
        this.viewportScroller.scrollToAnchor(event.anchor);
      } else if (event.position) {
        // Scroll to the saved position
        this.viewportScroller.scrollToPosition(event.position);
      } else {
        // Scroll to the top
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    }
  });

  private previousPath = '';

  constructor(viewport: ViewportScroller) {
    viewport.setOffset([0, this.scrollToOffset]);
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  private smoothScroll(targetElement: HTMLElement | null): void {
    if (!targetElement) {
      return;
    }
    const yOffset = this.scrollToOffset * -1; // Your offset value
    const yPosition = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    setTimeout(() => {
      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });
    }, 0);
  }
}
