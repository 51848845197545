import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Alert } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getAlerts() {
    return this.http.get<Alert[]>(`${this.BASE_URL}api/alerts`);
  }
}
